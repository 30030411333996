<template>
  <div>
    <b-card bg-variant="dark" header-tag="header" footer-tag="footer" style="height: 750px;">
      <b-nav tabs>
        <b-nav-item>
          <router-link :to="'/system-traffic'" :exact="true">Sistem trafiği </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="'/request-log'" :exact="true">İstem trafiği </router-link>
        </b-nav-item>
        <b-nav-item active>
          <router-link :to="'/networkholter'" :exact="true">Network Holter </router-link>
        </b-nav-item>

        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/database-document-management'" :exact="true">Veritabanı Döküman Yönetimi </router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1)">
          <router-link :to="'/customer-support'" :exact="true"> Müşteri Destek </router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/system-management'" :exact="true">WisdomEra Sistem Yönetimi</router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/data-structure-analyze'" :exact="true">Veri Yapısı Analizi</router-link>
        </b-nav-item>
      </b-nav>
      <b-card bg-variant="dark" style="margin-bottom: 0px;" no-body>
        <b-row>
          <b-col cols="12">
            <b-card bg-variant="dark" style="margin-bottom: 0px;" no-body>
              <b-card-header header-bg-variant="dark" header-text-variant="white" class="p-1">
                <i class="fa fa-file-text-o"></i> Network Holter Ekranı
              </b-card-header>
              <b-row style="padding: 3px;">
                <b-col sm="12" md="8">
                  <b-card bg-variant="secondary" no-body style="color: white; margin-bottom: 0px;">
                    <b-card-header header-bg-variant="dark" header-text-variant="white" class="p-1">
                      <i class="fa fa-search"></i> Kullanıcı Seçimi
                    </b-card-header>
                    <b-row>
                      <b-col cols="12">
                        <v-select ref="selected_brand" name="selected_brand" v-model="selected_user" :options="all_users"></v-select>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-input type="date" id="selected_date" v-model="selected_date"></b-form-input>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col sm="12" md="4"></b-col>
              </b-row>
              <b-card-footer footer-bg-variant="dark" header-text-variant="white" class="p-1">
                <b-row>
                  <b-col sm="12" md="4"></b-col>
                  <b-col sm="12" md="4">
                    <b-button variant="warning" size="md" style="width: 100%;" @click="f_updateUserNetworkHolter()"><i class="fa fa-cloud-download"></i> güncelle </b-button>
                  </b-col>
                  <b-col sm="12" md="4">
                  </b-col>
                </b-row>
              </b-card-footer>
            </b-card>
            <b-card bg-variant="dark" no-body style="height: 100%;">
              <b-card-header header-bg-variant="dark" header-text-variant="white" class="p-1">
                <i class="fa fa-file-text-o"></i> Holter Grafiği
                <template v-if="selected_user">
                  [ {{ selected_user.label }} ]
                </template>
              </b-card-header>
              <div class="chart-wrapper" v-if="d_showChart" style="overflow-x: auto; height: 100%;">
                <line-view :chart_data="chart_data" style="height:100px;" :height="100" />
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import GlobalService from '@/services/global';
import { mapGetters } from "vuex";
import vSelect from 'vue-select';
import LineView from '@/components/chart/LineView';
import {
  default as Modal
} from "@/components/widgets/Modal";

export default {
  name: "NetworkHolter",
  computed: {
    ...mapGetters({
      lang: "lang"
    })
  },
  data () {
    return {
      d_showChart: true,
      chart_data: {},
      chart_annotation: {},
      user: {},
      all_users: [],
      selected_user: '',
      selected_date: '',
      d_userNetworkHolterData: {}
    };
  },
  mounted: function () {
    this.$options.sockets.onmessage = function (message) {};
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    let all_users = JSON.parse(localStorage.getItem('all_users'));
    for (let i in all_users) {
      this.all_users.push({ 'label': all_users[i].first_name + ' ' + all_users[i].last_name, 'value': all_users[i].username });
    }
  },
  methods: {
    f_updateUserNetworkHolter: function () {
      if (this.selected_user && this.selected_date) {
        let data = { 'date': this.selected_date, 'user': this.selected_user.value };
        GlobalService.get_user_holter(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_userNetworkHolterData = resp.data.result;
              this.chart_data = {
                backgroundColor: "#fff",
                labels: [],
                datasets: [{
                  label: 'holter',
                  lineTension: 0,
                  borderColor: '#20a8d8',
                  backgroundColor: '#ffffff00',
                  showLine: true,
                  // backgroundColor: hexToRgba('#20a8d8', 90),
                  data: []
                }]
              };
              for (let i in this.d_userNetworkHolterData.list) {
                this.chart_data.datasets[0].data.push(this.d_userNetworkHolterData.list[i].ms);
                let x_value = this.d_userNetworkHolterData.list[i].time.split(':')[0] + ' : ' + this.d_userNetworkHolterData.list[i].time.split(':')[1];
                // her 10 adetten bir tanesinde x labelını gösteriyoruz.
                if (parseInt(i) === 0) {
                  this.chart_data.labels.push(x_value);
                } else {
                  if (parseInt(i) % 20 === 0) {
                    this.chart_data.labels.push(x_value);
                  } else {
                    this.chart_data.labels.push('');
                  }
                }
              }
              this.f_showTrueFalse();
            } else {
              this.d_userNetworkHolterData = {};
              alert('error', resp.data.message);
            }
          })
      } else {
        alert('lütfen tarih ve kullanıcı seçimini yapınız');
      }
    },
    f_showTrueFalse: function () {
      this.d_showChart = false;
      setTimeout(function () {
        this.d_showChart = true;
      }.bind(this), 500)
    }
  },
  watch: {},
  components: {
    Modal,
    vSelect,
    LineView
  }
};

</script>

<style type="text/css">
canvas {
  background-color: white;
}

</style>

